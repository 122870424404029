
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'SignOutButton',

  computed: {
    ...mapGetters('auth', {
      user: 'oidcUser',
    })
  },

  methods: {
    signOut (): void {
      this.$store.dispatch('auth/signOutOidc')
    },
  },
})
