import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = [".operation", ".resource", ".shape", ".error", ".submitting", "submit-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("cc-hydra-operation-form", {
    ".operation": _ctx.operation,
    ".resource": _ctx.resource,
    ".shape": _ctx.shape,
    ".error": _ctx.error,
    ".submitting": _ctx.isSubmitting,
    "submit-label": _ctx.submitLabel,
    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit', $event))),
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
  }, null, 40, _hoisted_1))
}