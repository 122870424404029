import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_button = _resolveComponent("o-button")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_o_button, {
          onClick: _ctx.signOut,
          "icon-right": "power-off",
          title: "Sign out"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.user.name), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]))
    : _createCommentVNode("", true)
}