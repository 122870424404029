
import { defineComponent, PropType } from 'vue'
import type { ColorsModifiers } from '@oruga-ui/oruga/types/helpers'

export default defineComponent({
  name: 'DialogConfirm',
  props: {
    title: {
      type: String,
      default: 'Are you sure?',
    },
    message: {
      type: String,
      required: true,
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    variant: {
      type: String as PropType<ColorsModifiers>,
      default: 'danger',
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['confirm', 'cancel', 'close'],

  computed: {
    icon () {
      if (!this.hasIcon) {
        return null
      }

      switch (this.variant) {
        case 'info':
          return 'information'
        case 'success':
          return 'check-circle'
        case 'warning':
          return 'alert'
        case 'danger':
          return 'alert-circle'
        default:
          return null
      }
    },
  },

  methods: {
    onConfirm (): void {
      this.$emit('confirm')
      this.$emit('close')
    },

    onCancel (): void {
      this.$emit('cancel')
      this.$emit('close')
    },
  },
})
