import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uppy_dashboard = _resolveComponent("uppy-dashboard")!
  const _component_loading_block = _resolveComponent("loading-block")!

  return (_ctx.uppy)
    ? (_openBlock(), _createBlock(_component_uppy_dashboard, {
        key: 0,
        uppy: _ctx.uppy,
        props: _ctx.uppyDashboardOptions
      }, null, 8, ["uppy", "props"]))
    : (_openBlock(), _createBlock(_component_loading_block, { key: 1 }))
}