
import { DimensionMetadata } from '@cube-creator/model'
import { defineComponent, PropType } from 'vue'
import type { Literal } from '@rdfjs/types'
import HydraOperationButton from './HydraOperationButton.vue'
import DataKindIcon from './DataKindIcon.vue'
import ScaleOfMeasureIcon from './ScaleOfMeasureIcon.vue'
import TermDisplay from './TermDisplay.vue'
import TermWithLanguage from './TermWithLanguage.vue'

export default defineComponent({
  name: 'CubePreviewDimension',
  components: {
    DataKindIcon,
    HydraOperationButton,
    ScaleOfMeasureIcon,
    TermDisplay,
    TermWithLanguage,
  },
  props: {
    dimension: {
      type: Object as PropType<DimensionMetadata>,
      required: true,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
    cubeUri: {
      type: String,
      required: true,
    },
  },

  computed: {
    description (): string {
      const description = this.dimension.description.find(({ language }) => language === this.selectedLanguage)

      return description?.value ?? ''
    },

    linkToSharedDimensionLabel (): string {
      const dimension: any = this.dimension

      if (dimension.sharedDimensions.length > 0) {
        const label = dimension.sharedDimensions.map(({ label }: { label: Literal }) => `"${label.value}"`).join(' & ')
        return `Linked to ${label} (click to edit)`
      } else if (dimension.mappings) {
        return 'Link to shared dimension'
      } else {
        return ''
      }
    },
  },
})
