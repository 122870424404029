import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_ctx.scaleOfMeasure)
    ? (_openBlock(), _createBlock(_component_o_tooltip, {
        key: 0,
        label: _ctx.label,
        class: "tag is-rounded is-primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_o_icon, { icon: _ctx.icon }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["label"]))
    : _createCommentVNode("", true)
}