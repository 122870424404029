import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_4 = {
  key: 2,
  class: "pl-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hydra_operation_error_result = _resolveComponent("hydra-operation-error-result", true)!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.propertyLabel(_ctx.result.path)), 1),
    _createTextVNode(":  "),
    (_ctx.usefullMessages.length === 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.usefullMessages[0]), 1))
      : (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usefullMessages, (message, messageIndex) => {
            return (_openBlock(), _createElementBlock("li", { key: messageIndex }, _toDisplayString(message), 1))
          }), 128))
        ])),
    (_ctx.result.detail && _ctx.result.detail.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.detail, (detail, detailIndex) => {
            return (_openBlock(), _createBlock(_component_hydra_operation_error_result, {
              key: detailIndex,
              result: detail,
              shape: _ctx.shape
            }, null, 8, ["result", "shape"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}