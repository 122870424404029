import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13ca0ed0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = {
  key: 0,
  class: "control"
}
const _hoisted_4 = {
  key: 1,
  class: "control"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_field = _resolveComponent("o-field")!

  return (_openBlock(), _createBlock(_component_o_field, { addons: false }, {
    default: _withCtx(() => [
      (!_ctx.inline)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
        : _createCommentVNode("", true),
      _createVNode(_component_o_field, {
        class: "buttons",
        addons: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_button_loading, {
              variant: _ctx.submitButtonVariant,
              disabled: _ctx.disabled,
              loading: _ctx.isSubmitting,
              onClick: _ctx._submit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx._submitLabel), 1)
              ]),
              _: 1
            }, 8, ["variant", "disabled", "loading", "onClick"])
          ]),
          (_ctx.showCancel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_o_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Cancel ")
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.showClear)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_o_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clear')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Clear ")
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}