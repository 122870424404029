
import { defineComponent, PropType } from 'vue'
import type { Shape } from '@rdfine/shacl'
import $rdf from '@rdf-esm/data-model'
import { sh } from '@tpluscode/rdf-ns-builders'
import { ValidationReport } from '@/api/errors'

export default defineComponent({
  name: 'HydraOperationErrorResult',
  props: {
    result: {
      type: Object as PropType<ValidationReport>,
      required: true,
    },
    shape: {
      type: Object as PropType<Shape | null>,
      default: null,
    },
  },

  computed: {
    usefullMessages (): string[] {
      return this.result.message.filter((message: string) => !message.startsWith('Value does not have shape'))
    },
  },

  methods: {
    propertyLabel (uri: string | undefined): string {
      if (!uri) return ''

      const shrunkProperty = uri.split('#').slice(-1)[0]

      if (!this.shape) return shrunkProperty

      const label = this.shape.pointer.any()
        .has(sh.path, $rdf.namedNode(uri))
        .out(sh.name, { language: ['en', ''] })
        .value

      return label || shrunkProperty
    },
  },
})
