
import { defineComponent, PropType } from 'vue'
import type { Term } from '@rdfjs/types'
import { ResourceIdentifier } from '@tpluscode/rdfine'
import { ColumnMapping, Table } from '@cube-creator/model'
import BMessage from './BMessage.vue'
import HydraOperationButton from './HydraOperationButton.vue'
import PropertyDisplay from './PropertyDisplay.vue'
import { confirmDialog } from '../use-dialog'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'MapperTable',
  components: { BMessage, HydraOperationButton, PropertyDisplay },
  props: {
    table: {
      type: Object as PropType<Table>,
      required: true,
    }
  },
  emits: ['highlight-arrows', 'unhighlight-arrows'],

  computed: {
    ...mapGetters('project', {
      getTable: 'getTable',
    }),

    errors (): Array<{ id: ResourceIdentifier, description?: string }> {
      const columnErrors = this.table.columnMappings
        .flatMap(cm => (cm.errors || []).map(({ id, description }) => ({
          id,
          description: `Property ${cm.targetProperty?.value}: ${description}`
        })))

      return [
        ...(this.table.errors || []),
        ...columnErrors,
      ]
    },

    prefix (): string {
      return this.table.isObservationTable
        ? 'Cube:'
        : 'Concept:'
    },
  },

  methods: {
    getTableColor (tableId: Term): string {
      try {
        return this.getTable(tableId).color
      } catch {
        return 'red'
      }
    },

    deleteTable (table: Table): void {
      confirmDialog({
        title: table.actions.delete?.title,
        message: 'Are you sure you want to delete this table?',
        confirmText: 'Delete',
        onConfirm: () => {
          this.$store.dispatch('api/invokeDeleteOperation', {
            operation: table.actions.delete,
            successMessage: `Table ${table.name} deleted successfully`,
            callbackAction: 'project/refreshTableCollection',
          })
        },
      })
    },

    deleteColumnMapping (columnMapping: ColumnMapping): void {
      confirmDialog({
        title: columnMapping.actions.delete?.title,
        message: 'Are you sure you want to delete this column mapping?',
        confirmText: 'Delete',
        onConfirm: () => {
          this.$store.dispatch('api/invokeDeleteOperation', {
            operation: columnMapping.actions.delete,
            successMessage: 'Column mapping deleted successfully',
            callbackAction: 'project/refreshTableCollection',
          })
        },
      })
    },
  },
})
