import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d7026d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dimension" }
const _hoisted_2 = { class: "dimension-header" }
const _hoisted_3 = { class: "has-text-danger" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_term_display = _resolveComponent("term-display")!
  const _component_term_with_language = _resolveComponent("term-with-language")!
  const _component_hydra_operation_button = _resolveComponent("hydra-operation-button")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!
  const _component_scale_of_measure_icon = _resolveComponent("scale-of-measure-icon")!
  const _component_data_kind_icon = _resolveComponent("data-kind-icon")!
  const _component_o_icon = _resolveComponent("o-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_term_with_language, {
        values: _ctx.dimension.name,
        "selected-language": _ctx.selectedLanguage
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            _createTextVNode(" Missing name for "),
            _createElementVNode("em", null, [
              _createVNode(_component_term_display, {
                term: _ctx.dimension.about,
                base: _ctx.cubeUri
              }, null, 8, ["term", "base"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["values", "selected-language"]),
      _createVNode(_component_hydra_operation_button, {
        operation: _ctx.dimension.actions.edit,
        to: { name: 'DimensionEdit', params: { dimensionId: _ctx.dimension.clientPath } }
      }, null, 8, ["operation", "to"]),
      (_ctx.dimension.mappings)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_o_tooltip, { label: _ctx.linkToSharedDimensionLabel }, {
              default: _withCtx(() => [
                _createVNode(_component_o_button, {
                  tag: "router-link",
                  to: { name: 'DimensionMapping', params: { dimensionId: _ctx.dimension.clientPath } },
                  "icon-left": "link",
                  size: "small",
                  variant: "text",
                  class: _normalizeClass({ 'has-text-primary': _ctx.dimension.sharedDimensions.length > 0 })
                }, null, 8, ["to", "class"])
              ]),
              _: 1
            }, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_scale_of_measure_icon, {
        "scale-of-measure": _ctx.dimension.scaleOfMeasure
      }, null, 8, ["scale-of-measure"]),
      _createVNode(_component_data_kind_icon, {
        "data-kind": _ctx.dimension.dataKind
      }, null, 8, ["data-kind"]),
      _withDirectives(_createVNode(_component_o_tooltip, { label: _ctx.description }, {
        default: _withCtx(() => [
          _createVNode(_component_o_icon, {
            icon: "comment-alt",
            pack: "far",
            variant: "primary"
          })
        ]),
        _: 1
      }, 8, ["label"]), [
        [_vShow, _ctx.description]
      ]),
      (_ctx.dimension.hierarchies.length)
        ? (_openBlock(), _createBlock(_component_o_tooltip, {
            key: 0,
            class: "tag is-rounded is-primary"
          }, {
            content: _withCtx(() => [
              _createTextVNode(" Hierarchies: "),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dimension.hierarchies, (hierarchy) => {
                return (_openBlock(), _createElementBlock("ul", {
                  key: hierarchy.id.value
                }, [
                  _createElementVNode("li", null, _toDisplayString(hierarchy.name), 1)
                ]))
              }), 128))
            ]),
            default: _withCtx(() => [
              _createVNode(_component_o_icon, { icon: "sitemap" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}