
import { defineComponent, PropType } from 'vue'
import { ValidationResult } from '@rdfine/shacl'
import PropertyDisplay from './PropertyDisplay.vue'

export default defineComponent({
  name: 'ValidationResultDisplay',
  components: { PropertyDisplay },
  props: {
    result: {
      type: Object as PropType<ValidationResult>,
      required: true,
    },
  },
})
