
import { ColorsModifiers } from '@oruga-ui/oruga/types/helpers'
import { defineComponent, PropType } from 'vue'
import ButtonLoading from './ButtonLoading.vue'

export default defineComponent({
  name: 'FormSubmitCancel',
  components: { ButtonLoading },
  props: {
    submitLabel: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showClear: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    submitButtonVariant: {
      type: String as PropType<ColorsModifiers>,
      default: 'primary',
    },
  },
  emits: ['cancel', 'clear', 'cc-submit'],

  computed: {
    _submitLabel (): string {
      return this.submitLabel || 'Save'
    }
  },

  methods: {
    _submit () {
      this.$emit('cc-submit')
    }
  }
})
