import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createBlock(_component_o_button, {
    "native-type": _ctx.nativeType,
    variant: _ctx.variant,
    size: _ctx.size,
    "icon-left": _ctx.icon,
    "icon-class": _ctx.iconClass,
    disabled: _ctx.disabled || _ctx.loading
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["native-type", "variant", "size", "icon-left", "icon-class", "disabled"]))
}