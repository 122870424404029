import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-card" }
const _hoisted_2 = { class: "modal-card-head" }
const _hoisted_3 = { class: "modal-card-title" }
const _hoisted_4 = { class: "modal-card-body" }
const _hoisted_5 = { class: "media-left" }
const _hoisted_6 = { class: "media-content" }
const _hoisted_7 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("button", {
        class: "delete",
        "aria-label": "close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
      })
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass({ media: _ctx.hasIcon })
      }, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_o_icon, {
                key: 0,
                icon: _ctx.icon,
                variant: _ctx.variant,
                size: "large",
                both: "",
                "aria-hidden": ""
              }, null, 8, ["icon", "variant"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.message), 1)
      ], 2)
    ]),
    _createElementVNode("footer", _hoisted_7, [
      _createVNode(_component_o_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_o_button, {
        variant: _ctx.variant,
        onClick: _ctx.onConfirm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
        ]),
        _: 1
      }, 8, ["variant", "onClick"])
    ])
  ]))
}