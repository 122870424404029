import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hydra_operation_error_result = _resolveComponent("hydra-operation-error-result")!
  const _component_b_message = _resolveComponent("b-message")!

  return (_ctx.error)
    ? (_openBlock(), _createBlock(_component_b_message, {
        key: 0,
        type: "is-danger",
        title: _ctx.error.title,
        class: "error-message"
      }, {
        default: _withCtx(() => [
          (_ctx.error.detail && !_ctx.error.report)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.error.detail), 1))
            : _createCommentVNode("", true),
          (_ctx.error.report)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.error.report, (result, resultIndex) => {
                  return (_openBlock(), _createBlock(_component_hydra_operation_error_result, {
                    key: resultIndex,
                    result: result,
                    shape: _ctx.shape
                  }, null, 8, ["result", "shape"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}