
import { defineComponent, PropType } from 'vue'
import type { Shape } from '@rdfine/shacl'
import { ErrorDetails } from '@/api/errors'
import BMessage from './BMessage.vue'
import HydraOperationErrorResult from './HydraOperationErrorResult.vue'

export default defineComponent({
  name: 'HydraOperationError',
  components: { BMessage, HydraOperationErrorResult },
  props: {
    error: {
      type: Object as PropType<ErrorDetails | null>,
      default: null,
    },
    shape: {
      type: Object as PropType<Shape | null>,
      default: null,
    },
  },
})
