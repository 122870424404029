import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createBlock(_component_o_tooltip, { label: _ctx.expanded }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.shrunk), 1)
    ]),
    _: 1
  }, 8, ["label"]))
}